body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.div-ques {
  display: inline-block;
  width: 250px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.div-ques-list {
  margin: 4px, 4px;
  padding: 4px;
  height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.shadow-video{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.homepage-bgimage {
  background: url("../assets/sigin.jpg");
  height: 100vh;
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: #ffffff;
}

.face-canvas {
  left: 0;
  position: absolute;
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(16, 16, 16, 0.5);
  z-index: 999990;
}

.loading.line-scale {
  margin-top: 50vh;
  margin-left: 50vw;
  z-index: 999999;
  color: #00bda5;
}

.button-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.audio-controls,
.video-controls {
  margin-bottom: 20px;
}

.audio-player,
.video-player {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.audio-player,
.video-player,
.recorded-player {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.live-player {
  height: 200px;
  width: 400px;
  border: 1px solid #646cff;
  margin-bottom: 30px;
}

.recorded-player video {
  height: 400px;
  width: 800px;
}

.btn {
  text-decoration: none;
  border: none;
  padding: 12px 40px;
  font-size: 16px;
  background-color: green;
  color: #fff;
  border-radius: 5px;
  box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  outline: none;
  transition: 0.2s all;
}

/* Adding transformation when the button is active */

.btn:active {
  transform: scale(0.98);
  /* Scaling button to 0.98 to its original size */
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  /* Lowering the shadow */
}

.videoContainer {
  margin-top: 5px;
  position: relative;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #4f5050;
  /* box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px; */
  border: none;
  border-radius: 10px;
}

.card-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.card {
  width: 30%;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.dashboard-cards-container {
  margin-top: 24px;
  justify-content: space-evenly;
}

.dashboard-cards-container .cards {
  width: 14vw;
  position: relative;
  height: 130px;
  /*margin-right: 16px; !* 8*2 *!*/
}
.dashboard-cards-container .card-icon-container {
  position: absolute;
  background-color: #e5e4ff;
  border-radius: 23%;
  top: 28%;
  right: 15px;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  opacity: 30%;
}
.dashboard-cards-container .card-icon-container svg {
  font-size: 48px;
  color: #646cff;
  margin: auto;
  display: block;
}

.dashboard-cards-container .card-trend {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.dashboard-cards-container .card-trend span.color-green {
  color: #00bda5;
}
.dashboard-cards-container .card-trend span.color-red {
  color: #ff4d4f;
}
.dashboard-cards-container .card-trend span.color-green svg {
  vertical-align: middle;
}

.profile-btn Button {
  margin: 5px;
}

.pointer {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #0959aa;
}
.theme-dark a {
  color: #fff;
}
a:hover {
  text-decoration: underline;
}
#menu-appbar .MuiPopover-paper.MuiMenu-paper.MuiMenu-paper {
  top: 50px !important;
  width: 200px;
}

.theme-dark .MuiDataGrid-menu .MuiPaper-root {
  background-color: rgba(9, 14, 16, 1);
}
/* @media for width 0 to 768px */
@media (max-width: 768px) {
  .dashboard-cards-container .cards {
    width: 100%;
  }
  .dashboard-cards-container .card-icon-container {
    top: 0;
    right: 0;
  }
  .dashboard-cards-container .card-trend {
    margin-top: 0;
  }
}

.tt-capitalize {
  text-transform: capitalize;
}

.modalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  background-color: white;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
}

.modalTitle {
  font-size: 24px;
  margin-bottom: 2%;
}

.modalForm {
  display: flex;
  flex-direction: column;
}

.modalFormItem {
  margin-bottom: 16px;
}

.modalActions {
  display: flex;
  justify-content: flex-end;
}

.modalButton {
  margin-left: 8px;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modalButton:hover {
  background-color: #f0f0f0;
}

.modalCloseButton {
  background-color: #f44336;
  color: white;
}

.modalCloseButton:hover {
  background-color: #d32f2f;
}

.width80 {
  width: 80%;
}

.width18 {
  width: 18%;
}

.mr8px {
  margin-right: 8px !important;
}

.flexRowView{
  display: flex;
  flex-direction: row;
}

.spaceBetween{
  justify-content: space-between;
}
