.tooltip-content {
    background-color: white;
    color: black;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 200px;
    font-size: 14px;
  }
  
  .tooltip-content h3 {
    margin: 0 0 10px;
    font-size: 16px;
  }
  
  .tooltip-content ul {
    padding-left: 20px;
    margin: 0;
  }
  
  .tooltip-content li {
    list-style-type: disc;
    margin-bottom: 5px;
  }
  