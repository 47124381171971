/* body {
  font-family: 'Arial', sans-serif;
  background: #f9fafb;
  margin: 0;
  padding: 2rem;
  color: #374151;
  min-height: 100vh;
} */

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.container-public {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
}

.loading-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  min-height: 100vh; /* Full viewport height */
  background: #f9fafb; /* Match body background */
}

.translate-row {
  display: flex;
  justify-content: flex-end; /* Align button to the right */
  margin-bottom: 1rem; /* Space between button row and header */
}

.translate-btn {
  background: #ffffff;
  color: #2563eb;
  font-weight: 500;
  font-size: 0.875rem; /* Matches text-sm */
  padding: 0.5rem 1rem;
  border: 1px solid #2563eb;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.translate-btn:hover {
  background: #2563eb;
  color: #ffffff;
}

@media (max-width: 767px) {
  .translate-row {
    justify-content: flex-start; /* Left-align on mobile for readability */
  }
}

/* Header */
.header {
  background: linear-gradient(to right, #2563eb, #1e40af);
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  overflow: hidden;
  padding: 1.25rem 1.5rem;
}

.header .flex {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .header .flex {
    flex-direction: row;
    align-items: center;
  }
  .header .right {
    text-align: right;
  }
}

.header h1 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  margin: 0;
}

.header .info {
  display: flex;
  gap: 1rem;
  color: #dbeafe;
  margin-top: 0.25rem;
}

.header .name {
  font-size: 1.25rem;
  font-weight: 600;
  color: #fff;
}

.header p {
  color: #dbeafe;
  margin: 0;
}

/* Overall Score */
.grid-3 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin-top: 2rem;
}

@media (min-width: 768px) {
  .grid-3 {
    grid-template-columns: 2fr 1fr;
  }
}

.score-card {
  background: #fffef7; /* Changed from #fff to off-white/yellowish-white */
  padding: 1.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
}

.score-card h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.score-card .overall {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2563eb;
}

.progress-bar {
  width: 100%;
  background: #e5e7eb;
  height: 0.75rem;
  border-radius: 9999px;
  overflow: hidden;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.progress {
  height: 100%;
  background: linear-gradient(to right, #2563eb, #60a5fa);
  border-radius: 9999px;
  transition: width 0.5s ease;
}

.score-card .details {
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #6b7280;
}

.overview-card h3 {
  font-size: 1.125rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 0.75rem;
}

.overview-card .stat {
  margin-bottom: 0.75rem;
}

.overview-card .label {
  font-size: 0.875rem;
  color: #6b7280;
}

.overview-card .value {
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
}

/* Dimensional Analysis */
.analysis-card {
  background: #fffef7; /* Changed from #fff to off-white/yellowish-white */
  padding: 1.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  margin-top: 2rem;
}

.analysis-card h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 1.5rem;
}

.grid-2 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-top: 2rem;
}

@media (min-width: 768px) {
  .grid-2 {
    grid-template-columns: 1fr 1fr;
  }
}

.chart-container {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-item {
  background: #f9fafb;
  padding: 1rem;
  border: 1px solid #f3f4f6;
  border-radius: 0.5rem;
  transition: transform 0.3s ease;
  margin-top: 1rem;
}

.section-item:hover {
  transform: translateY(-3px);
}

.section-item h3 {
  font-weight: 500;
  color: #111827;
  margin-bottom: 0.5rem;
}

.section-item .score {
  font-size: 1.125rem;
  font-weight: 600;
  color: #2563eb;
}

.section-item p {
  font-size: 0.875rem;
  color: #6b7280;
  margin: 0.5rem 0;
}

.traits {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.trait {
  font-size: 0.75rem;
  background: #eff6ff;
  color: #1e40af;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
}

/* Key Findings */
.findings-card {
  background: #fffef7; /* Changed from #fff to off-white/yellowish-white */
  padding: 1.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.findings-card h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #e5e7eb;
}

.findings-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.finding-item {
  display: flex;
  align-items: flex-start;
  padding: 0.75rem 0;
  border-bottom: 1px solid #f3f4f6;
  transition: background-color 0.2s ease;
}

.finding-item:last-child {
  border-bottom: none;
}

.finding-item:hover {
  background-color: #f9fafb;
}

.bullet-green {
  width: 0.5rem;
  height: 0.5rem;
  margin-top: 0.5rem;
  margin-right: 1rem;
  background: #10b981;
  border-radius: 9999px;
  flex-shrink: 0;
}

.bullet-blue {
  width: 0.5rem;
  height: 0.5rem;
  margin-top: 0.5rem;
  margin-right: 1rem;
  background: #2563eb;
  border-radius: 9999px;
  flex-shrink: 0;
}

.finding-text {
  font-size: 0.9rem;
  color: #374151;
  line-height: 1.4;
}

/* Detailed Questions List */
.questions-card {
  background: #fffef7; /* Changed from #fff to off-white/yellowish-white */
  padding: 1.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  margin-top: 2rem;
}

.questions-card h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #e5e7eb;
}

.questions-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.section-group {
  margin-bottom: 2rem;
}

.section-heading {
  font-size: 1.125rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e5e7eb;
}

.questions-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

@media (max-width: 768px) {
  .questions-grid {
    grid-template-columns: 1fr;
  }
}

.question-item {
  background: #afafaf;
  padding: 1rem;
  border: 1px solid #f3f4f6;
  border-radius: 0.5rem;
  transition: transform 0.3s ease;
}

.question-item:hover {
  transform: translateY(-3px);
}

.question-item h4 {
  font-size: 0.95rem;
  font-weight: 500;
  color: #111827;
  margin-bottom: 0.75rem;
}

.options-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.option-item {
  font-size: 0.875rem;
  color: #374151;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.option-item.selected {
  background: #eff6ff;
  color: #1e40af;
  font-weight: 500;
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.answer-tag {
  font-size: 0.75rem;
  background: #2563eb;
  color: #fff;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
}

/* Footer */
.footer {
  background: #f9fafb;
  padding: 1.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  margin-top: 2rem;
}

.footer .flex {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

@media (min-width: 768px) {
  .footer .flex-row {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .footer .right {
    text-align: right;
  }
}

.footer hr {
  border-color: #e5e7eb;
  margin: 1rem 0;
}

.footer .icon-text {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.footer .icon {
  width: 1.25rem;
  height: 1.25rem;
  color: #2563eb;
}

.footer ul {
  list-style: disc;
  padding-left: 1.25rem;
  font-size: 0.875rem;
  color: #6b7280;
}

.footer ul li {
  margin-bottom: 0.25rem;
}

.footer .small {
  font-size: 0.75rem;
  color: #9ca3af;
  margin-top: 1rem;
}

.footer .small p {
  margin: 0;
}

.footer .small .mt-1 {
  margin-top: 0.25rem;
}

/* Utility Classes */
.text-gray-900 { color: #111827; }
.font-medium { font-weight: 500; }
.text-sm { font-size: 0.875rem; }
.text-gray-600 { color: #6b7280; }
.text-xs { font-size: 0.75rem; }
.text-gray-500 { color: #9ca3af; }
.text-gray-700 { color: #374151; }
.mb-2 { margin-bottom: 0.5rem; }

/* Add to the footer section in CSS */
.footer .verified-stamp {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: #eff6ff;
  border-radius: 9999px;
  margin-top: 1rem;
}

.footer .verified-stamp .icon {
  width: 1.5rem;
  height: 1.5rem;
  color: #2563eb;
}

@media (max-width: 767px) {
  .footer .verified-stamp {
    margin-top: 1.5rem;
    justify-content: center;
  }
}